import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import PaymentIcon from "@mui/icons-material/Payment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import HelpIcon from "@mui/icons-material/Help";
import InventoryIcon from "@mui/icons-material/Inventory";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CollectionsIcon from "@mui/icons-material/Collections";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import DiscountIcon from "@mui/icons-material/Discount";
import { Link } from "react-router-dom";
import "./AdminSidebar.css";

const AdminSidebar = () => {
  return (
    <div className="admin-sidebar-container">
      <span>
        <small>MAIN</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-dashboard">
            <HomeIcon className="me-2" />
            Dashboard
          </Link>
        </li>
      </ul>
      <span>
        <small>LISTS</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-orders">
            <ReceiptIcon className="me-2" />
            Orders
          </Link>
        </li>
        <li>
          <Link to="/admin-products">
            <InventoryIcon className="me-2" />
            Products
          </Link>
        </li>
        <li>
          <Link to="/admin-users">
            <GroupIcon className="me-2" />
            Customers
          </Link>
        </li>
        <li>
          <Link to="/admin-payments">
            <PaymentIcon className="me-2" />
            Payments
          </Link>
        </li>
        <li>
          <Link to="/admin-queries">
            <HelpIcon className="me-2" />
            Queries
          </Link>
        </li>

        <li>
          <Link to="/admin-banners">
            <ViewCarouselIcon className="me-2" />
            Banners
          </Link>
        </li>

        <li>
          <Link to="/admin-add-coupon">
            <DiscountIcon className="me-2" />
            Coupons
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminSidebar;
